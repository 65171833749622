import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import "../../assets/style/components/_hero.scss"
import Tag from "./Tag"
import Play from "../../assets/svg/play.svg"
export default function Hero({
  title,
  subTitle,
  buttons,
  diagonally,
  video,
  image,
  label,
}) {
  return (
    <div className="hero container-fluid">
      <div className="row">
        <div className="col-xs-12 col-sm-8 col-md-6">
          <div className="hero-left">
            {diagonally && <div className="hero-left-diagonally" />}
            <div className="hero-left-content">
              {label && <Tag color={label?.color}>{label?.message}</Tag>}
              <h1>{title}</h1>
              <div
                className="text-paragraph hero-left-content-subtitle"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              ></div>
              <div className="hero-left-content-buttons">
                {buttons?.map(button => button)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-6">
          <div className="hero-media">
            {!video?.url ? (
              <img src={image?.url} alt={image?.alt} />
            ) : (
              <HeroVideo image={image} video={video} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
Hero.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.node,
  buttons: PropTypes.array,
  diagonally: PropTypes.bool,
  image: PropTypes.object,
  label: PropTypes.object,
}

const HeroVideo = ({ image, video }) => {
  const [play, setPlay] = useState(false)
  const vidRef = useRef()
  const togglePlay = () => {
    if (!play) {
      setPlay(true)
      vidRef.current.play()
    } else{
      setPlay(false)
      vidRef.current.pause()
    }
  }
  return (
    <div className="hero-video">
      <video ref={vidRef} id="hero-video" onEnded={togglePlay} onClick={togglePlay}>
        <source src={video?.url} alt={video?.alt} type={video?.mimeType} />
        Your browser does not support HTML video.
      </video>
      {!play && (
        <>
        <img src={image?.url} alt={image?.alt}/>
          <div className="hero-video-play" onClick={togglePlay}>
            <Play />
          </div>
        </>
      )}
    </div>
  )
}
