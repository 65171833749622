import React from 'react'
import PropTypes from 'prop-types'
import "../../assets/style/components/_tag.scss"
export default function Tag({color, children}) {
  return (
    <div className={`tag ${color}`}>
        {children}
    </div>
  )
}
Tag.propTypes = {
    color: PropTypes.oneOf(["green", "orange", "red"]),
}
