import React, {useState, useEffect } from "react"
import PropTypes from "prop-types"
import "../../assets/style/components/_flex-container.scss"
import FormGroup from "./FormGroup"
export default function FlexContainer({ gap, gapSm, children, className, filter, direction }) {
  const mediaMatch = typeof window !== "undefined" && window.matchMedia('(max-width: 576px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [matchedGap, setMatchedGap] = useState()
  useEffect(() => {
      const handler = e => setMatches(e.matches);
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
  }, []);
  useEffect(() => {
    if(gapSm && matches === true){
      setMatchedGap(gapSm)
    } else{
      setMatchedGap(gap)
    }
  }, [matches])
  return (
    <div className={className}>
      {filter && (
        <div className="flex-filter row">
          <div className="col-xs-12 col-md-3">
          <FormGroup
            type="select"
            // width="max-content"
            placeholder="Alle anzeigen"
            id="filter-all"
            options={[
              { label: "Fitness & Gesundheit", value: "fitness" },
              { label: "Wettkampf", value: "wettkampf" },
              { label: "Kurse", value: "kurse" },
            ]}
          />
          </div>
          <div className="col-xs-12 col-md-6">
          <FormGroup
            type="search"
            // width="100%"
            placeholder="Suche nach z.B. “Gymnastik”"
            id="filter-search"
          />
          </div>
          <div className="col-xs-12 col-md-3">
           <FormGroup
            type="select"
            label="Weitere Filter"
            // width="max-content"
            placeholder="Damen- oder Herrensport"
            id="filter-gender"
            options={[
              { label: "Damensport", value: "women" },
              { label: "Herrensport", value: "men" },
              { label: "Kindersport", value: "kids" },
              { label: "Jugendsport", value: "teenager" },
              { label: "Seniorensport", value: "senior" },
              { label: "Mannschaftssport", value: "team" },
            ]}
          />
          </div>
        </div>
      )}
      <div className={`flex-container`} style={{ gap: matchedGap, justifyContent: direction === "left" ? "flex-start" : "center" }}>
        {children}
      </div>
    </div>
  )
}
FlexContainer.propTypes = {
  gap: PropTypes.string,
  gapSm: PropTypes.string,
  className: PropTypes.string,
  filter: PropTypes.bool,
  direction: PropTypes.oneOf(["center", "left"])
}
FlexContainer.defaultProps = {
  direction: "center"
}