import React from "react"
import "../../assets/style/components/_cta.scss"
import Button from "./Button"
export default function CTA() {
  return (
    <div className="cta text-center component-margin">
      <div className="cta-top">
        <span className="note">Jetzt starten</span>
        <p className="heading-3">Melde dich jetzt an</p>
        {/* <p className="text-paragraph">
          Denn ”Sport ist am schönsten im TV Lahr!”
        </p> */}
        <div className="cta-buttons">
          <Button type="secondary" url="/kontakt">Kontakt</Button>
          <Button type="primary" url="/formulare">Zum Aufnahmeformular</Button>
        </div>
      </div>
      <div className="cta-bottom container">
        <div className="cta-media">
          <img alt="cta-tv-lahr" src="https://www.datocms-assets.com/77261/1665134088-rueckenfit_bbp_3.jpg"/>
        </div>
      </div>
    </div>
  )
}
