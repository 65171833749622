import React from "react"
import PropTypes from "prop-types"
import "../../assets/style/components/_statistics.scss"
export default function Statistics({ content }) {
  return (
    <div className="statistics">
      {content?.map(item => {
        return (
          <div className="statistics-item text-center">
            <span>{item?.value}</span>
            <p>{item?.label}</p>
          </div>
        )
      })}
    </div>
  )
}
Statistics.propTypes = {
  content: PropTypes.array,
}
