import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Accordion from "../components/ui/Accordion"
import Block from "../components/ui/Block"
import Button from "../components/ui/Button"
import WisCalendar from "../components/ui/Calendar"
import Card from "../components/ui/Card"
import CTA from "../components/ui/CTA"
import FlexContainer from "../components/ui/FlexContainer"
import Hero from "../components/ui/Hero"
import Icon from "../components/ui/Icon"
import Statistics from "../components/ui/Statistics"
import TestimonialSlider from "../components/ui/TestimonialSlider"
import StartVideo from "../assets/videos/start-video.mp4"
export default function Index({ data }) {
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["index, follow"],
      path: "/",
      lang: "de",
    },
  }
  const content = data?.allDatoCmsSport.nodes
  return (
    <Layout pageContext={pageContext}>
      <Hero
        title="Herzlich Willkommen beim Turnverein Lahr"
        subTitle='Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr. Unter dem Motto: "Komm mit - wir machen dich fit!" kannst du in 2 Schnupperstunden die für dich geeignete Abteilung oder Sportart finden.'
        buttons={[
          <Button url="/kontakt/" type="secondary" withoutArrow>
            Kontakt
          </Button>,
          <Button url="/formulare/" type="primary" withoutArrow>
            Zum Aufnahmeformular
          </Button>,
        ]}
        image={{
          url: "https://www.datocms-assets.com/77261/1671356702-bildschirm-foto-2022-12-18-um-10-44-59.png",
          alt: "tv-lahr",
        }}
        video={{
          url: StartVideo,
          alt: "tv-lahr",
        }}
        diagonally
      />
      {/* <Block
        title="Unsere Kurse"
        // content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        note="Kurse"
        marginBottom="60px"
        marginTop="128px"
      >
        <iframe id="654a69ed6d2f3626ddd2650a" width="100%" height="150" className="yolawo-widget" src="https://widgets.yolawo.de/w/654a69ed6d2f3626ddd2650a" frameborder="0" data-offset-x="50" scrolling="no"></iframe><script type="text/javascript" src="https://widgets.yolawo.de/assets/script.js"></script>
      </Block> */}
      <Block
        title="Der größte Sport anbietende Verein in Lahr"
        content="Wir bieten vielfältige, sportliche Aktivitäten an. Dazu gehören verschiedene Wettkampfsportarten und attraktive Angebote im Bereich Freizeit-, Fitness- und Gesundheitssport für Kinder, Jugendliche, Erwachsene und Senioren."
        textCenter
      >
        <Statistics
          content={[
            {
              label: "Abteilungen",
              value: 40,
            },
            {
              label: "Mitglieder",
              value: 1700,
            },
            {
              label: "Übungsleiter:innen",
              value: 90,
            },
          ]}
        />
      </Block>
      {/* <Block
        title="Unser Fitness- und Kursangebot"
        content="GYMWELT ist ein von den Turnerbünden entwickeltes Markenzeichen für moderne und qualitativ hochwertige Angebote im Fitness- und Gesundheitssport der teilnehmenden Vereine."
        note="Gymwelt"
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Block
              title="Gemeinsam aktiv für die Gesundheit"
              content="Mit Gymwelt möchten wir alle Menschen ansprechen, die gemeinsam mit Spaß und Freude aktiv sind."
              note={<Icon size="small" icon="heart" />}
              link={{ url: "", text: "Mehr erfahren" }}
              compact
            />
            <Block
              title="Attraktive Bewegungsangebote"
              content="Teilnehmenden Vereine bieten moderne und qualitativ hochwertige Angebote im Fitness- und Gesundheitssport an."
              note={<Icon size="small" icon="lightning" />}
              link={{ url: "", text: "Mehr erfahren" }}
              compact
            />
            <Block
              title="Herausragende Qualität durch Weiterbildungen"
              content="Über 11.000 Übungsleiter:innen werden in den 3.000 gemeinnützigen Turn- und Sportvereinen in ganz Baden-Württemberg stetig aus- und fortgebildet."
              note={<Icon size="small" icon="quality" />}
              link={{ url: "", text: "Mehr erfahren" }}
              compact
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div style={{ width: "100%", aspectRatio: "1/1" }}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://www.datocms-assets.com/77261/1661699046-srb1686.jpg"
              />
            </div>
          </div>
        </div>
      </Block> */}

      <Block
        title="Unsere Sportarten von A – Z"
        // content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        note="Sport"
        marginBottom="60px"
        marginTop="128px"
        extra={
          <Button type="primary" url="/sportarten">
            Alle Ansehen
          </Button>
        }
      >
        <FlexContainer gap="24px">
          {content.map(item => (
              <Card
                imageUrl={item?.image?.url}
                title={item?.title}
                content={item?.subtitle}
                note={item?.sportart}
                href={`/sportarten/${item?.slug}/`}
              />
            ))}
          {/* <Card
            imageUrl="https://www.datocms-assets.com/77261/1661698846-srb1813.jpg"
            title="Dance"
            content="Mit allen Gruppen starten wir auf Dance-Wettbewerben und -Wettkämpfen."
            note="Wettkampf"
          />
          <Card
            imageUrl="https://www.datocms-assets.com/77261/1661698839-srb1931.jpg"
            title="Fechten"
            content="Momentan zählt unsere kleine, aber feine Abteilung um die 65 Mitglieder, davon etwa 40 aktive Fechter."
            note="Wettkampf"
          />
          <Card
            imageUrl="https://www.datocms-assets.com/77261/1661698853-srb1983.jpg"
            title="Basketball"
            content="Mittlerweile ist aus unserem Hobby-Basketball bei den Erwachsenen eine Wettkampfabteilung geworden."
            note="Wettkampf"
          /> */}
        </FlexContainer>
      </Block>
      <div className="main-container row component-margin sportstaetten">
        <div className="col-xs-12 col-md-4">
          <Block
            title="Unsere Trainingsorte"
            // content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum laoreet diam varius ornare at in."
            note={<Icon size="large" icon="fast-lightning" />}
            marginTop="0"
            marginBottom="0"
            noContainer
          />
        </div>
        <div className="col-xs-12 col-md-8">
          <FlexContainer gap="48px" gapSm="8px">
            <Block
              title="Aktienhof"
              content="Dinglinger Hauptstr. 51 b , 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Bürgerpark Sporthalle und Mehrzweckhalle"
              content="77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Clara-Schumann-Gymnasium Turnhalle"
              content="Pestalozzistr., 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Eichrodtschule Turnhalle"
              content="Tiergartenmühlgasse, 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Friedrichschule Turnhalle"
              content="Seminarstr., 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Geroldseckerschule Turnhalle"
              content="Altfelixstr. 10, 77933 Lahr "
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Gutenbergschule Turnhalle"
              content="Gutenbergstr., 77933 Lahr "
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Hallensportzentrum"
              content="Halle I und Halle II, Martin-Luther-Str. 22, 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Max-Planck-Gymnasium Turnhalle und Gymnastiksaal"
              content="Max-Planck-Str. 12, 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
            <Block
              title="Stadion Dammenmühle"
              content="Unterer Dammen 25, 77933 Lahr"
              compact
              marginTop="0"
              marginBottom="0"
              style={{ width: "348px" }}
            />
          </FlexContainer>
        </div>
      </div>
      {/* <TestimonialSlider
        content={[
          {
            name: "Kelly Williams",
            sport: "Basketball",
            text: "Ich bin seit über 10 Jahren aktives Mitglied im TV Lahr und bin besonders von der Atmosphäre und dem freundlichen Miteinander überzeugt",
            imageUrl:
              "https://sevdesk.imgix.net/34060/1602753003-marcel1-1.jpg",
          },
          {
            name: "Marcel Wiskow",
            sport: "Fussball",
            text: "Ich bin seit über 10 Jahren aktives Mitglied im TV Lahr und bin besonders von der Atmosphäre und dem freundlichen Miteinander überzeugt",
            imageUrl:
              "https://sevdesk.imgix.net/34060/1602753003-marcel1-1.jpg",
          },
          {
            name: "David Hansen",
            sport: "Baseball",
            text: "Ich bin seit über 10 Jahren aktives Mitglied im TV Lahr und bin besonders von der Atmosphäre und dem freundlichen Miteinander überzeugt",
            imageUrl:
              "https://sevdesk.imgix.net/34060/1602753003-marcel1-1.jpg",
          },
        ]}
      /> */}
      <div className="main-container row component-margin">
        <div className="col-xs-12 col-md-5">
          <Block
            title="Häufig gestellte Fragen"
            content="Hier bekommen Sie häufig gestellte Fragen direkt beantwortet."
            note="Support"
            marginTop="0"
            marginBottom="0"
            noContainer
          />
        </div>
        <div className="col-xs-12 offset-md-1 col-md-6">
          <Accordion />
        </div>
      </div>
      <CTA />
      {/* <WisCalendar/> */}
    </Layout>
  )
}

export const query = graphql`
  query StartSportQuery {
    allDatoCmsSport(limit: 3, sort: {fields: title, order: ASC}) {
      nodes {
        image {
          alt
          url
        }
        slug
        sportart
        subtitle
        title
      }
    }
  }
`
