import React from 'react'
import PropTypes from "prop-types"
import '../../assets/style/components/_card.scss'
import ArrowTopRight from "../../assets/svg/arrow-top-right.svg"
function truncate(input) {
  if (input.length > 150) {
     return input.substring(0, 150) + '...';
  }
  return input;
};
export default function Card({imageUrl, note, title, content, type, imageStyle, href}) {
  return (
    <div className={`card card-${type}`}>
        <div className="card-media">
            <img src={imageUrl} alt={title} style={{aspectRatio: type==="person" ? "1/1" : "", ...imageStyle}}/>
        </div>
        <div className="card-text">
        {/* <span className="note-small">{note}</span> */}
            {href ?
            <a href={href} className="heading-4 card-text-title">{title}{type === "normal" && <ArrowTopRight/>}</a>
          : <p href={href} className="heading-4 card-text-title">{title}{type === "normal" && <ArrowTopRight/>}</p>}
            <div className="text-paragraph-small card-text-content" dangerouslySetInnerHTML={{__html: truncate(content)}} />
        </div>
    </div>
  )
}
Card.propTypes = {
    imageStyle: PropTypes.object,
    imageUrl: PropTypes.string.isRequired,
    note: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.node,
    type: PropTypes.oneOf(["normal", "person"]),
    href: PropTypes.string
}
Card.defaultProps = {
  type: "normal"
}