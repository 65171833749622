import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "../../assets/style/components/_icon.scss"
import Heart from "../../assets/svg/heart.svg"
import Lightning from "../../assets/svg/lightning.svg"
import Quality from "../../assets/svg/quality.svg"
import FastLightning from "../../assets/svg/fast-lightning.svg"
export default function Icon({ size, icon }) {
  const [svg, setSvg] = useState()
  useEffect(() => {
    switch (icon) {
      case "heart":
        setSvg(<Heart/>)
        break
      case "lightning":
        setSvg(<Lightning/>)
        break
        case "fast-lightning":
        setSvg(<FastLightning/>)
        break
      case "quality":
        setSvg(<Quality/>)
        break
      default:
        setSvg(<Heart/>)
        break
    }
  }, [])
  return (
    <div className={`icon icon-${size}`}>
      <div className="icon icon-out">
        <div className="icon icon-mid">
          <div className="icon icon-in">
            {svg}
          </div>
        </div>
      </div>
    </div>
  )
}
Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(["large", "small"]).isRequired,
}
