import React from "react"
import '../../assets/style/components/_accordion.scss'
export default function Accordion() {
  return (
    <div className="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button heading-6"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
           Ist Probetraining möglich?
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body text-paragraph-small">
          Aber natürlich, grundsätzlich gilt bei uns dass jede/r 2 x in den Abteilungen schnuppern kann, an denen sie/er interessiert ist. Danach muss man sich entscheiden, ob man weiter teilnehmen möchte und sich über unseren Aufnahmeantrag anmelden.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className="accordion-button heading-6 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Was kostet eine Mitgliedschaft im Verein?
          </button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body text-paragraph-small">
          Die Mitgliedsbeiträge findet man auf unserem Aufnahmeformular, auf der Rückseite.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className="accordion-button heading-6 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Wo sehe ich ob es freie Plätze in den Abteilungen gibt?
          </button>
        </h2>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body text-paragraph-small">
          Auf unserer Homepage gibt es bei jeder Abteilungsbeschreibung ein Ampelsystem, grün bedeutet freie Plätze, rot bedeutet Aufnamestopp. In diesem Fall bitte bei unserer Geschäftsstelle melden, damit wir euch auf die Warteliste setzen können.

          </div>
        </div>
      </div>
    </div>
  )
}
